'use strict';

angular.module('enervexSalesappApp').service('AccountMembershipRequestService', function(AccountMembershipRequest, AccountMembershipRequestComment, $stateParams){
	return {
		approveRequest:function(){
			return AccountMembershipRequest.approve({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
			});
		},
		denyRequest: function(){
			return AccountMembershipRequest.deny({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
			});
		},
		getComments: function(){
			return AccountMembershipRequestComment.query({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
			});
		},
		makeComment: function(comment){
			return AccountMembershipRequestComment.save({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
			}, comment);
		},
		removeComment: function(comment){
			return AccountMembershipRequestComment.remove({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
				id: comment._id
			});
		},
		updateComment: function(comment){
			return AccountMembershipRequestComment.update({
				accountMembershipRequestId: $stateParams.accountMembershipRequestId,
				id: comment._id
			}, comment);
		}
	}
});
